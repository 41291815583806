const semverGte = require('semver/functions/gte')
const { fusionPersonas, springboardPersonas } = require('./personaItems');
const { fusionObjectives, springboardObjectives } = require('./objectiveItems');
const { hideAppTypes } = require('./appTypes');

const MANAGED_FUSION_TAG='managed-fusion';
const SPRINGBOARD_TAG = 'springboard';
const FUSION_TAG = 'fusion';
const SMART_ANSWERS_TAG = 'smart-answers';
const PREDICTIVE_MERCHANDISER_TAG = 'predictive-merchandiser';
const EXPERIENCE_OPTIMIZER_TAG = 'experience-optimizer';
const APP_STUDIO_TAG = 'app-studio';
const FUSION_SERVER_TAG = 'fusion-server';
const FUSION_AI_TAG = 'fusion-ai';
const FUSION_CONNECTORS_TAG = 'fusion-connectors';
const NEVER_NULL_TAG = 'never-null';
const STYLE_GUIDE_TAG = 'style-guide';
const LUCIDWORKS_PLATFORM_TAG = 'lw-platform';

const allVersions = ['4.2','5.5','5.9','5.10','5.11','5.12'];
const fusion4Tags = [FUSION_SERVER_TAG, FUSION_AI_TAG, APP_STUDIO_TAG]

const productItems = [
  {
    name: 'Lucidworks Platform',
    link: '/lw-platform/platform/fh9alw/welcome-to-lucidworks-platform',
    alternateLink: '/lw-platform/platform/rcwjfo/lucidworks-platform',
    description: 'Lucidworks Platform is a software-as-a-service (SaaS) that offers access to products and functions such as Cloud Search, Lucidworks AI, and Analytics.',
    tag: LUCIDWORKS_PLATFORM_TAG,
    icon: '/assets/icons/product-noLogo.svg',
    searchable: true,
    apptypes: ['platform', 'analytics', 'ai'],
    subGroups: {
      platform: {
        prettyName: 'Lucidworks Platform',
        link: '/lw-platform/platform/rcwjfo/lucidworks-platform'
      },
      analytics: {
        prettyName: 'Lucidworks Analytics',
        link: '/lw-platform/analytics/10aut8/analytics'
      },
      ai: {
        prettyName: 'Lucidworks AI',
        link: '/lw-platform/ai/lukosg/lucidworks-ai'
      }
    },
    highlight: [
      { 
        title: 'Lucidworks Platform',
        link: '/lw-platform/platform/rcwjfo/lucidworks-platform?ref=homepage',
        icon: 'how-to'
      },
      // {
      //   title: 'Lucidworks Analytics',
      //   link: '/lw-platform/analytics/10aut8/analytics?ref=homepage',
      //   icon: 'dev'
      // },
      {
        title: 'Lucidworks AI',
        link: '/lw-platform/ai/lukosg/lucidworks-ai?ref=homepage',
        icon: 'connector'
      }
    ],
    isHomeGrid: true,
    personas: fusionPersonas,
    objectives: fusionObjectives,
  },
  {
    name: 'Managed Fusion',
    link: '/managed-fusion/$version/ckqr0j/managed-fusion',
    alternateLink: '/managed-fusion/5.9/ckqr0j',
    description: 'Managed Fusion is a cloud-based search platform built on a secure and scalable technology stack. This AI-powered solution allows you to deploy search applications designed for data discovery and can handle massive volumes of information and queries, accommodating even the most demanding search needs. To ensure successful search operations, Lucidworks collaborates with you to provide support, environment changes, migrations and upgrades, and overall search workflows.',
    tag: MANAGED_FUSION_TAG,
    icon: '/assets/icons/product-Server.svg',
    versions: ['5.5','5.10', '5.11','5.12','5.9'],
    searchable: true,
    isHomeGrid: true,
    personas: fusionPersonas,
    objectives: fusionObjectives,
    highlight: [
      { 
        title: 'APIs',
        link: '/managed-fusion/5.9/t51gkk/ap-is?ref=homepage',
        icon: 'dev'
      },
      {
        title: 'Index your data',
        link: '/managed-fusion/5.9/uwmzgu/index-data?ref=homepage',
        icon: 'connector'
      },
      {
        title: 'How-to guides',
        link: '/how-to?q=*%3A*&wt=json&fq=productName:("managed-fusion")&fq=productVersion:("5.9")&tags=howto-homepage-default&start=0&rows=10',
        icon: 'how-to'
      }
    ]
  },
  {
    name: 'Fusion',
    link: '/fusion/$version/6764/fusion',
    alternateLink: '/fusion/5.9/6764/fusion/',
    description: 'Fusion 5 grants organizations the autonomy of self-hosted AI-powered data discovery and search applications, leveraging a modern, containerized, and cloud-native architecture. This empowers effortless ingestion and indexing of diverse data sources, scaling to handle billions of records.',
    tag: FUSION_TAG,
    icon: '/assets/icons/product-Server.svg',
    versions: ['5.5','5.10','5.11','5.12','5.9'],
    searchable: true,
    isHomeGrid: true,
    subMenu: 'Fusion 5',
    personas: fusionPersonas,
    objectives: fusionObjectives,
    highlight: [
      { 
        title: 'Fusion REST APIs',
        link: '/fusion/5.9/424/fusion-rest-ap-is?ref=homepage',
        icon: 'dev'
      },
      {
        title: 'Connectors SDK',
        link: '/fusion/5.9/15/connectors-sdk?ref=homepage',
        icon: 'connector'
      },
      {
        title: 'Release notes',
        link: '/fusion/5.9/97/release-notes?ref=homepage',
        icon: 'release'
      },
      {
        title: 'How-to guides',
        link: '/how-to?q=*%3A*&wt=json&fq=productName:("fusion")&fq=productVersion:("5.9")&tags=howto-homepage-default&start=0&rows=10',
        icon: 'how-to'
      }
    ]
  },
  { 
    name: 'Smart Answers',
    link: '/fusion/$version/461/smart-answers',
    alternateLink: '/fusion/5.5/461/smart-answers/',
    tag: SMART_ANSWERS_TAG,
    icon: '/assets/icons/category-queryContent.svg',
    versions: ['5.5','5.9','5.10','5.11','5.12'],
    subMenu: 'Fusion 5',
  },
  {
    name: 'Predictive Merchandiser',
    link: '/fusion/$version/591/predictive-merchandiser',
    alternateLink: '/fusion/5.5/591/predictive-merchandiser/',
    description: 'How to craft a custom search experience with Predictive Merchandiser.',
    tag: PREDICTIVE_MERCHANDISER_TAG,
    icon: '/assets/icons/category-analyzeData.svg',
    versions: ['5.5','5.9','5.10','5.11','5.12'],
    subMenu: 'Fusion 5',
  },
  {
    name: 'Experience Optimizer',
    link: '/fusion/$version/62cqt4/experience-optimizer/',
    alternateLink: '/fusion/5.5/62cqt4/experience-optimizer/',
    tag: EXPERIENCE_OPTIMIZER_TAG,
    icon: '/assets/icons/capability-EO.svg',
    versions: ['5.5','5.9','5.10','5.11','5.12'],
    subMenu: 'Fusion 5'
  },
  {
    name: 'App Studio',
    link: '/fusion/$version/963/fusion-app-studio',
    alternateLink: '/fusion/5.5/963/fusion-app-studio',
    tag: APP_STUDIO_TAG,
    icon: '/assets/icons/product-AppStudio.svg',
    versions: ['5.5','5.9','5.10','5.11','5.12'],
    subMenu: 'Fusion 5',
  },
  {
    name: 'Fusion Connectors',
    link: '/fusion-connectors/2/fusion-connectors',
    alternateLink: '/fusion-connectors/2/fusion-connectors',
    description: 'Our comprehensive connector ecosystem enables effortless ingestion of data from diverse sources. Fusion Connectors feature automatic updates, robust security measures, and superior scalability through distributed fetching.',
    tag: FUSION_CONNECTORS_TAG,
    icon: '/assets/icons/category-personalizeApps.svg',
    searchable: true,
    isHomeGrid: true,
    personas: fusionPersonas,
    objectives: fusionObjectives,
    highlight: [
      { 
        title: 'Java Connector Development',
        link: '/fusion/5.9/hpbgki/java-connector-development?ref=homepage',
        icon: 'dev'
      },
      {
        title: 'Connectors SDK Javadocs',
        link: '/fusion/10917/connectors-sdk-javadocs?ref=homepage',
        icon: 'connector'
      }
    ]
  },
  {
    name: 'Fusion Server',
    link: '/fusion-server/$version/8764/fusion-server',
    alternateLink: '/fusion-server/4.2/8764/fusion-server/',
    description: 'Built on the proven foundation of Solr and Spark, Fusion 4 is a highly scalable search platform that indexes and stores data for real-time discovery, empowering developers to build sophisticated applications for fast and accurate information retrieval.',
    tag: FUSION_SERVER_TAG,
    icon: '/assets/icons/product-Server.svg',
    versions: ['4.2'],
    searchable: true,
    isHomeGrid: true,
    subMenu: 'Fusion 4',
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
  { 
    name: 'Fusion AI',
    link: '/fusion-ai/$version/428/fusion-ai',
    alternateLink: '/fusion-ai/4.2/428/fusion-ai/',
    description: 'How to add intelligence to your search applications.',
    tag: FUSION_AI_TAG,
    icon: '/assets/icons/product-AI.svg',
    versions: ['4.2'],
    searchable: true,
    subMenu: 'Fusion 4',
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
  {
    name: 'App Studio',
    link: '/app-studio/$version/963/fusion-app-studio',
    alternateLink: '/app-studio/4.2/963/fusion-app-studio/',
    description: 'How to develop Fusion-powered search applications with App Studio.',
    tag: APP_STUDIO_TAG,
    icon: '/assets/icons/product-AppStudio.svg',
    versions: ['4.2'],
    searchable: true,
    subMenu: 'Fusion 4',
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
  {
    name: 'Springboard',
    link: '/springboard/connected-search/igtsjn/springboard',
    alternateLink: '/springboard/connected-search/igtsjn/springboard',
    description: 'Specifically designed for indexing and searching high-volume websites, Connected Search offers efficient content indexing, fine-tuned relevance ranking, and an intuitive user search experience.',
    tag: SPRINGBOARD_TAG,
    icon: '/assets/icons/product-Server.svg',
    searchable: true,
    isHomeGrid: true,
    apptypes: ['connected-search'],
    personas: springboardPersonas,
    objectives: springboardObjectives
  },
  {
    name: 'Never Null',
    link: '/never-null/et383x/never-null',
    alternateLink: '/never-null/et383x/never-null',
    description: 'Never Null is designed to elevate the capabilities of your existing search platform by tackling the challenge of queries that yield "0 results found."',
    tag: NEVER_NULL_TAG,
    icon: '/assets/icons/product-noLogo.svg',
    searchable: true,
    isHomeGrid: true,
    personas: fusionPersonas,
    objectives: fusionObjectives
  },
 {
    name: 'Lucidworks Style Guide',
    link: '/style-guide/xkv68a/lucidworks-style-guide',
    hidden: false,
    tag: STYLE_GUIDE_TAG,
  },
];

const productItemsPretty = {
  'managed-fusion': 'Managed Fusion',
  'springboard': 'Springboard',
  'fusion': 'Fusion',
  'fusion-server': 'Fusion Server',
  'fusion-ai': 'Fusion AI',
  'fusion-connectors': 'Fusion Connectors',
  'app-studio': 'App Studio',
  'never-null': 'Never Null',
  'lw-platform': 'Lucidworks Platform'
}


const findProductItemFromBasePath = (basePath) => {
  return productItems.find(element => {
    const elementLink = element.link;
    const elementSliceIndex = elementLink.indexOf('/', 1);
    const elementProduct = elementSliceIndex > 0 ? elementLink.slice(1, elementSliceIndex) : elementLink;
    return elementProduct === basePath
  })
}

const isValidProductVersion = (frontmatter,  version = 0) => {

  const skipBefore = frontmatter && frontmatter.skipBefore ? frontmatter.skipBefore : '1.0'
  const skipAfter = frontmatter && frontmatter.skipAfter ? frontmatter.skipAfter : '9.9'

  const lwPlatformSubGroups = ['ai', 'analytics', 'platform']

  let isValid = false
  if(version === 'connected-search' || !!lwPlatformSubGroups.includes(version)){
    isValid = true
  } else {
      isValid = semverGte(`${skipAfter}.0`, `${version}.0`) && semverGte(`${version}.0`,`${skipBefore}.0`);
  }

  return isValid
}

const findGlobalProduct = (basePath, version, productList) => {
  const foundProductObj = findProductItemFromBasePath(basePath)
  if(foundProductObj === undefined){
    return null;
  }
  let found;

  for(let i = 0; i < productList.length; i++){
    if(productList[i]?.options){
      for(let k = 0; k < productList[i].options.length; k++){
        const sub = productList[i].options[k]
        if((sub.value.split('.')[0] === foundProductObj.subMenu && sub.label.toLowerCase() === version) || (sub.tag === basePath && (sub.label.toLowerCase() === version || sub.label.replace(' ', '-') === version || sub.label.split(' ')[1]?.toLowerCase() === version))){ 
          found = sub
          break;
        }
      }
    } else if (!found && productList[i].tag === basePath){
        found = productList[i]
      break;
    }
    if(found !== undefined) break
  }

  return found;
}


// Helper funciton for 'productDropdownItems' to generate proper sidebar naming for Fusion 4 (Fusion Server 4.x -> Fusion 4.x), and all product landing page links
const getVersionOptions = (name, versionsList, link, tag, subGroups = null) => {
  if (name === 'Springboard'){
    return versionsList.map(ver => ({label: ver.replace('-', ' '), value: ver.replace('-', ' '), link: link.replace('$version', ver), tag}))
  }
  if (name === 'Lucidworks Platform'){
    return versionsList.filter(itm => !hideAppTypes.includes(itm)).map(ver => 
       ({label: subGroups[ver].prettyName, value: ver.replace('-', ' '), link: subGroups[ver].link, tag}) 
    )
  }
  return versionsList.map(ver => ({label: ver, value: `${name.replace(' Server', '')} ${ver}`, link: link.replace('$version', ver), tag}))
}

const visibleProducts = productItems.filter(prod => !prod.hidden)

// Use productItems to generate nested product/version selector values for sidebar
const productDropdownItems = visibleProducts.reduce((optionsArray, prod) => { 
  const isInOptionsArray = optionsArray.some(obj => (obj.label === prod.subMenu))
  if(!isInOptionsArray){
    if(prod.subMenu) return ([...optionsArray, {label: prod.subMenu, options: getVersionOptions(prod.name, prod.versions, prod.link, prod.tag).reverse(), tag: prod.tag}]);
    if(prod.versions) return ([...optionsArray, {label: prod.name, options: getVersionOptions(prod.name, prod.versions, prod.link, prod.tag).reverse(), tag: prod.tag}]);
    if(prod.apptypes) return (
      [
        ...optionsArray, 
        {
          label: prod.name, 
          options: getVersionOptions(prod.name, prod.apptypes, prod.link, prod.tag, prod?.subGroups).reverse(), 
          tag: prod.tag
        }
      ]
    );    
    return [...optionsArray, {label: prod.name, value: prod.name, link: prod.link, tag: prod.tag }]
  } 
  return optionsArray;
}, [])


module.exports = {
  MANAGED_FUSION_TAG,
  SPRINGBOARD_TAG,
  FUSION_TAG,
  SMART_ANSWERS_TAG,
  PREDICTIVE_MERCHANDISER_TAG,
  EXPERIENCE_OPTIMIZER_TAG,
  APP_STUDIO_TAG,
  FUSION_SERVER_TAG,
  FUSION_AI_TAG,
  FUSION_CONNECTORS_TAG,
  NEVER_NULL_TAG,
  LUCIDWORKS_PLATFORM_TAG,
  STYLE_GUIDE_TAG,
  allVersions,
  productItems,
  productItemsPretty,
  fusion4Tags,
  productDropdownItems,
  isValidProductVersion,
  findProductItemFromBasePath,
  findGlobalProduct
}
