import React, { useReducer } from 'react';

export const SideBarContext = React.createContext();
export const SideBarDispatchContext = React.createContext();

export const UPDATE_SIDEBAR_FACET = 'SIDEBAR_FACET';
export const UPDATE_NAV_DATA = 'NAV_DATA';
export const UPDATE_GLOBAL_VERSION = 'GLOBAL_VERSION';
export const UPDATE_GLOBAL_PRODUCT = 'GLOBAL_PRODUCT';

export const initialSideBarState = {
  currentVersion: '5.9',
  currentProduct: { label: "5.9", value: "Fusion 5.9", link: "/fusion/5.9/6764/fusion", tag: 'fusion' },
  navData: []
};

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_NAV_DATA: {
      return {
        ...state,
        navData: action.payload,
      };
    }
    case UPDATE_GLOBAL_VERSION: {
      return {
        ...state,
        currentVersion: action.payload,
      };
    }
    case UPDATE_GLOBAL_PRODUCT: {
      return {
        ...state,
        currentProduct: action.payload,
      }
    }
    default:
      throw new Error('Bad Action Type');
  }
}

// eslint-disable-next-line react/prop-types
const SideBarContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialSideBarState);
  return (
    <SideBarDispatchContext.Provider value={dispatch}>
      <SideBarContext.Provider value={state}>{children}</SideBarContext.Provider>
    </SideBarDispatchContext.Provider>
  );
};

export default SideBarContextProvider;
